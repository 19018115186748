.top-menu-admin {
    font-family: Geomanist, sans-serif !important;
    font-size: 0.8em !important;
    box-shadow: 0px 1px rgba(118, 118, 118, 0.429);
    margin-bottom: 1em;
    height: 5vh;
    background: linear-gradient(to bottom, #183b79, #284d91, #3860a9);

    .logo {
        margin-right: 1rem;
        width: 30px;
        height: 80px;
        color: #fff;
    }

    .avatar {
        width: 25px;
        height: 25px;
        line-height: 23px;
        border-radius: 50%;
        border: solid 1px;
        border-color: rgb(146, 146, 146);
        font-size: 10px;
        color: #fff;
        text-align: center;
        background: rgb(191, 191, 191);
        margin-right: 0.2em;
    }

    .name-descrip {
        margin-right: 2em;
        font-size: 0.9em;
    }

    .cerrar-sesion {
        color: #fff;
        margin-left: 0.5rem;
        margin-right: 1rem;
        font-size: 0.85rem;
        font-weight: 600;
    }

    .text-white .nav-link {
        color: lightgray;
        font-size: 1.2em;
        font-weight: 600;
    }

    .font-media {
        font-size: 0.9em;
        font-weight: 500;
        color: #383838;
    }
}