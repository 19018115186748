.custom-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .custom-table th,
  .custom-table td {
    padding: 10px; /* Ajusta el espacio entre el contenido y el borde de las celdas */
    border: 1px solid #ccc; /* Agrega líneas de separación */
    text-align: left; /* Alinea el texto a la izquierda en las celdas */
  }
  
  .custom-table th {
    background-color: #f2f2f2; /* Color de fondo para las celdas de encabezado */
    font-weight: bold;
  }
  
  .table{
    margin: 0 !important;
  }