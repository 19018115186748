.container-button {
    display: inline-flex;
    flex-direction: column;
    border: 1px solid rgb(168, 165, 165);
    width: auto;
    padding: 1rem;
    align-items: center;
    box-shadow: 6px 11px 5px 0px rgba(0, 0, 0, 0.75);
    border-radius: 1rem;
    transition: background-color 0.5s, color 0.5s;
    cursor: pointer;
    width: 210px;
    outline: none;
    color: black;

    a {
        all: unset;
    }
}

.container-button:hover {
    background-color: rgb(220, 241, 245);
    color: cadetblue;
}