.side-menu-admin {

    font-size: 14px;
    border-radius: 10px;
    background-color: #f5f6fa !important;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);

    .sidebar {
        top: 0;
        bottom: 0;
        left: 0;
        min-width: 1vw !important;
        z-index: 2000;
        padding: 10px 0 0;
        margin-bottom: 1em;

        .active {
            background-color: rgb(15, 49, 93);
        }
    }

    .text-nav {
        text-decoration: none;
        color: #000000;

        &:hover {
            color: rgb(6, 29, 79) !important;
            background: #334f7423;
        }
    }

    .icon {
        margin-right: .8em;
        width: 2rem;
        height: 1.2rem;
    }

    .logo {
        margin: 5px;
        width: 130px;
        height: 80px;
    }

    .mark {
        background-color: #f5f6fa;
        float: right;
    }
}

.nav-conteiner {
    display: flex;
    flex-direction: column;
}

.mobile-nav {
    display: none;
}

.mobile-nav-btn {
    font-size: 2em;
    margin: 10px;
    display: flex;
    flex-direction: column;
    width: 3rem;
    height: 3rem;
    border: 0;
    background: transparent;
    gap: 0.65rem;
}

.mobile-nav-btn:hover {
    -moz-opacity: 0.5;
    color: rgb(22, 37, 168);
    opacity: 0.8;
    transform: translate(3px, 3px);
}

@media only screen and (max-width: 767px) {
    .mobile-nav {
        display: flex;
    }

    .item-side2 {
        background-color: rgb(255, 255, 255);
        position: absolute;
        width: 22em;
        top: 9.8em;
        box-shadow: 0 50px 50px rgba(29, 2, 2, 0.5);
        min-height: 100vh;
        transition: transform 1s;
    }

    .item-side1 {
        background-color: rgb(255, 255, 255);
        width: 22em;
        position: absolute;
        top: 4.1em;
        box-shadow: 0 50px 50px rgba(29, 2, 2, 0.5);
        transition: transform 1s;
    }

    .text-nav {
        transition: transform 1s;
        border-bottom: solid 0px rgba(255, 255, 255, 0);
        transition: 0.4s ease-in-out;

        &:hover {
            box-shadow: 0 0 1px #2bb9f1, 0 0 4px #2bb9f1, 0 0 8px #2bb9f1;
            transform: translate(1px, 1px);
        }
    }
}